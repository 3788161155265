<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="currentUser && Object.keys(currentUser).length !== 0"
      :account="currentUser"
    />
    <b-card>
      <div class="head-card">
        <div class="head-card-top">
          <h4>{{ $t('page.user.payments') }}</h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
        <div class="head-card-bottom d-flex justify-content-between align-items-center">
          <strong v-if="currentUser && Object.keys(currentUser).length !== 0">
            {{ $t('page.payments.balance') }}: {{ currencyFormatter(currentUser.currency, currentCompaign.balance) }}
          </strong>
          <b-button-toolbar>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="success"
              class="btn-icon"
              :disabled="currentCompaign.status === 'active'"
              @click="increase"
            >
              <feather-icon
                icon="CreditCardIcon"
                size="16"
              />
              {{ $t('page.payments.increase') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              class="btn-icon ml-1"
              :disabled="currentCompaign.status === 'active'"
              @click="decrease"
            >
              <feather-icon
                icon="RefreshCcwIcon"
                size="16"
              />
              {{ $t('page.payments.decrease') }}
            </b-button>
          </b-button-toolbar>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div
        v-else
        class="users-table mt-2"
      >
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          style-class="vgt-table striped"
        >

          <!-- Slot: Table Column -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span
              v-if="props.column.label ==='Date'"
              class="text-nowrap"
            >
              {{ $t('page.payments.date') }}
            </span>
            <span
              v-else-if="props.column.label ==='Type'"
              class="text-nowrap"
            >
              {{ $t('page.payments.type') }}
            </span>
            <span
              v-else-if="props.column.label ==='Sum'"
              class="text-nowrap"
            >
              {{ $t('page.payments.sum') }}
            </span>
            <span
              v-else-if="props.column.label ==='Source'"
              class="text-nowrap"
            >
              {{ $t('page.payments.source') }}
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>

          <!-- Slot: Table Row -->
          <template
            slot="table-row"
            slot-scope="props"
          >

            <span
              v-if="props.column.field === 'date'"
              class="text-nowrap"
            >
              {{ getFormattedDate(new Date(props.row.date), true) }}
            </span>

            <span
              v-if="props.column.field === 'type'"
              class="text-nowrap"
            >
              {{ $t(`page.payments.${props.row.type}`) }}
            </span>

            <span
              v-if="props.column.field === 'amount' && currentUser !== null"
              class="text-nowrap"
              :class="{
                'text-danger': props.row.type === 'refund' || props.row.type === 'transferTo' || props.row.type === 'decrease',
                'text-success': props.row.type !== 'refund' || props.row.type !== 'transferTo' || props.row.type !== 'decrease'
              }"
            >
              {{ props.row.type === 'refund' || props.row.type === 'transferTo' || props.row.type === 'decrease' ? '-': '+' }}
              {{ currencyFormatter(currentUser.currency, props.row.amount) }}
            </span>
            <span
              v-if="props.column.field === 'source'"
              class="text-nowrap"
            >
              {{ props.row.source }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t('message.pagelength') }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5','10','20','40']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>

</template>

<script>
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate from '@/utils/date-formatter'
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard, BSpinner, BPagination, BFormSelect, BButton, BButtonToolbar, VBTooltip,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    Breadcrumbs,
    BSpinner,
    BCard,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
    BButtonToolbar,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      breadcrumbItems: [],
      getFormattedDate,
      currencyFormatter,
      loading: true,
      currentUser: {},
      currentUserCompaigns: [],
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Sum',
          field: 'amount',
        },
        {
          label: 'Source',
          field: 'source',
        },
      ],
      rows: [],
      payAmount: 0,
      changeAmount: 0,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    currentCompaign() {
      return this.currentUserCompaigns.filter(c => c._id === this.$route.params.id)[0] || []
    },
  },
  created() {
    this.fetchCampaignPayments()
    this.getAccountCompaigns()
  },
  methods: {
    async getAccountCompaigns() {
      const responseData = await useJwt.accountCampaigns(this.$route.params.userId)
      this.currentUserCompaigns = responseData.data.campaigns || []
    },
    async fetchCampaignPayments() {
      this.loading = true
      const responseData = await useJwt.getCampaignPayments(this.$route.params.id)
      this.rows = responseData.data || []
      this.fetchUser()
      this.loading = false
    },
    async fetchUser() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.currentUser = responseData.data.account
      this.setBreabcrumbs()
    },
    increase() {
      this.$swal({
        title: this.$t('page.payments.refund'),
        // text: 'Amount:',
        html: `${this.$t('page.payments.sum')}: <br /><input id="pay-input" placeholder="100" type="number" class="mt-1 swal2-input" required/>`,
        // input: 'number',
        // inputValue: this.payAmount,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: this.$t('form.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        preConfirm: () => {
          const val = document.getElementById('pay-input').value
          this.payAmount = val
          return !val || val === '0' ? this.$swal.showValidationMessage('You need to write something!') : true
        },
      }).then(async result => {
        if (result.value) {
          const payment = {
            user_id: this.$route.params.userId,
            campaign_id: this.$route.params.id,
            source: 'system',
            amount: this.payAmount,
            type: 'increase',
          }

          await useJwt.campaignPay(payment)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$i18n.t('form.success'),
                  text: `You ${payment.type}: ${currencyFormatter(this.currentUser.currency, payment.amount)}`,
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.fetchCampaignPayments(payment.user_id)
              this.getAccountCompaigns()
            })
            .catch(errors => {
              if (errors.response && errors.response.status === 422) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errors.response.data.errors[0].detail,
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    decrease() {
      this.$swal({
        title: this.$t('page.payments.charge'),
        // text: 'Amount:',
        html: `${this.$t('page.payments.sum')}: <br /><input id="pay-input" placeholder="100" type="number" class="mt-1 swal2-input" required/>`,
        // input: 'number',
        // inputValue: this.changeAmount,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: this.$t('form.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        preConfirm: () => {
          const val = document.getElementById('pay-input').value
          this.changeAmount = val
          return !val || val === '0' ? this.$swal.showValidationMessage('You need to write something!') : true
        },
      }).then(async result => {
        if (result.value) {
          const payment = {
            user_id: this.$route.params.userId,
            campaign_id: this.$route.params.id,
            source: 'system',
            amount: this.changeAmount,
            type: 'decrease',
          }

          await useJwt.campaignPay(payment)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$i18n.t('form.success'),
                  text: `You ${payment.type}: ${currencyFormatter(this.currentUser.currency, payment.amount)}`,
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.fetchCampaignPayments(payment.user_id)
              this.getAccountCompaigns()
            })
            .catch(errors => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errors[0].detail,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: 'users',
          to: '/users',
        },
        {
          text: this.currentUser.name,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'campaigns',
          to: `/users/${this.$route.params.userId}/campaigns`,
        },
        {
          text: this.currentCompaign.title,
          to: `/users/${this.$route.params.userId}/campaigns/${this.$route.params.id}`,
        },
        {
          text: 'page.user.payments',
          active: true,
        },
      ]
    },
  },
}
</script>
